@import url("../fonts/font-awesome-4.7.0/css/font-awesome.css");

body {
  margin: 0;
  padding: 0;
  background-color: #EBEEF2;
  font-family: "Fira Sans", sans-serif;
}
* {
  outline: none;
}
.wrapper {
  width: 100%;
  height: 100vh;
}
.login-page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  background: #EBEEF2;
}
.login-main-section {
  width: 400px;
  box-sizing: border-box;
  background: #FFFFFF;
  margin: auto;
}
.login-form-title {
  width: 100%;
  background: #EBEEF2;
  padding-bottom: 20px;
}
.wrap-input100 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
  height: 40px;
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  margin-bottom: 10px;
}
.input100 {
  display: block;
  width: 100%;
  background: transparent;
  font-size: 18px;
  color: #555555;
  line-height: 1.2;
  padding: 0 26px;
  height: 100%;
  border: none;
  font-weight: 300;
}
.input100::placeholder {
  color: #999;
  font-weight: 300;
}
.txt1 {
  font-size: 13px;
  line-height: 1.4;
  color: #555555;
}
.login-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background: #133895;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
}
.login-form-btn:hover {
  background: #333333;
}
.container-login-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.top-section {
  background: #133895;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 70px;
  box-shadow: 0px 1px 5px #9e9e9e;
}
.topbar-left {
  display: flex;
  align-items: center;
}
.topbar-left img {
  width: 190px;
}
.topbar-right {
  display: flex;
  align-items: center;
}
.topbar-right i {
  font-size: 20px;
  margin-right: 6px;
}
.topbar-right a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  height: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  cursor: pointer;
}
.topbar-right a:hover {
  background: #333333;
}
.content-section {
  background: #EBEEF2;
  display: flex;
  padding-top: 30px;
  height: 100vh;
}
.select-field {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  height: 45px;
  font-size: 14px;
  color: #555555;
  font-weight: 300;
  width: 100%;
  padding: 0 10px;
  font-weight: bold;
}
.input-lg {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  height: 45px;
  font-size: 14px;
  color: #555555;
  font-weight: 300;
  max-width: 100%;
  padding: 0 10px;
  font-weight: bold;
}
.container {
  width: 1240px;
  margin: 0 auto;
}

.form-selection-wrapper {
  margin-top: 30px;
}
.select-box-wrapper {
  max-width: 250px;
  position: relative;
  min-width: 100px;
}
.select-box-wrapper span {
  position: absolute;
  right: 10px;
  top: 13px;
  pointer-events: none;
  color: #6b6b6b;
}
.select-field::-ms-expand {
  display: none;
}
.select-field {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.main-heading {
  font-weight: 600;
  color: #133895;
}
.heading-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.widget-row {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin: 0 -15px;
}
.info-box {
  box-shadow: 0 3px 8px 0 rgba(116, 129, 141, 0.1);
  border: 1px solid #e6ecf1;
  background: #fff;
  border-radius: 5px;
  width: 25%;
  text-align: center;
  margin: 0 15px;
  padding: 15px 0;
}
.info-box h4 {
  font-weight: 400;
  color: #666;
  margin-bottom: 10px;
}
.info-box h1 {
  color: #133895;
  margin-top: 5px;
  font-weight: 500;
}
.breadcrumb-section {
  padding: 10px 0;
}

.error {
  color: #fd517d;
  font-size: 18px;
}
.scheduleContainer {
  display: flex;
}
.hovButtDisable:hover {
  border: 1px solid #009da9 !important;
}
.hovButt:hover {
  background-color: #009da9 !important;
  border: 1px solid #009da9 !important;
}
/* ========================================================================================*/

.table-outter {
  border: 1px solid #e6ecf1;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(116, 129, 141, 0.1);
}
.table {
  width: 100%;
  border-collapse: collapse;
}
.table th,
.table tr {
  border-bottom: 1px solid #f5f7f9;
}
.table th {
  padding: 18px 15px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  color: #133895;
}
.table td {
  padding: 12px 15px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  vertical-align: middle;
}
.table.no-border td {
  border-top: none;
}
.table thead th {
  vertical-align: initial;
}
.hover-col {
  color: #aeb8c1;
}

.hover-col:hover {
  color: #133895;
  cursor: pointer;
}
.hover-col i {
  font-size: 18px;
}
.widget-border-blue {
  border-top: 5px solid #06b5dd;
}
.widget-border-pink {
  border-top: 5px solid #fd517d;
}
.widget-border-orange {
  border-top: 5px solid #ffc717;
}
.widget-border-green {
  border-top: 5px solid #80cf00;
}

.call-details-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.call-details-left {
  width: 50%;
  display: flex;
}
.call-details-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.call-btns {
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.call-btns i {
  font-size: 35px;
  color: #aeb8c1;
  margin-right: 20px;
}
.play-btn:hover,
.play-btn.active {
  color: #43c152;
  cursor: pointer;
}
.pause-btn:hover,
.pause-btn.active {
  color: #f94a4a;
  cursor: pointer;
}
.col-4 {
  width: 33.33%;
}
.table.no-border tr {
  border-bottom: none;
}
.matrics-wrap {
  display: flex;
}
.matrics-outter {
  border: 1px solid #e6ecf1;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(116, 129, 141, 0.1);
  margin-top: 0px;
  padding: 15px 0;
  margin-bottom: 30px;
}
.col-img {
  display: flex;
  align-items: center;
  width: 9px;
  margin-top: -20px;
}
.attempt-status-select {
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.attempt-status {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px 30px -15px;
}
.attect-widget {
  border: 1px solid #e6ecf1;
  box-shadow: 0 3px 8px 0 rgba(116, 129, 141, 0.1);
  border-radius: 6px;
  padding: 15px;
  box-sizing: border-box;
  width: 20%;
  max-height: 145px;
  margin: 0 15px;
  background: #fff;
}
.attect-widget.disabled {
  pointer-events: none;
}
.attect-widget.disabled h4,
.attect-widget.disabled h4 span,
.attect-widget.disabled h6,
.attect-widget.disabled p,
.attect-widget.disabled h5 {
  color: #d2d2d2;
}

.attect-widget h4 {
  padding: 0;
  margin: 0;
  font-size: 19px;
  color: #0d3c70;
}
.attect-widget h4 span {
  font-weight: 500;
  font-size: 14px;
}
.attect-widget h6 {
  margin: 8px 0;
  font-weight: 500;
  font-size: 13px;
  color: #00af15;
}
.attect-widget p {
  margin: 12px 0 0;
  font-size: 13px;
  color: #666;
}
.attect-widget hr {
  color: #dadada;
}
.attect-widget h5 {
  margin: 0;
  color: #133895;
  background: #f5f5f5;
  padding: 7px 5px;
  margin-top: 6px;
}
.table-data-hyperlink {
  color: rgb(34, 34, 182);
  cursor: pointer;
}
/* ========================================================================================*/
.btn {
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 0 15px;
}
.primary-btn {
  background: #133895;
  border: 1px solid #133895;
  border-radius: 5px;
  color: #fff;
}
.primary-btn:hover {
  background: #333333;
  border: 1px solid #333333;
  color: #fff;
}
.secondary-btn {
  background: #009da9;
  border: 1px solid #009da9;
  border-radius: 5px;
  color: #fff;
}

.secondary-btn:hover {
  background: #333333;
  border: 1px solid #333333;
  color: #fff;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.align-center {
  display: flex;
  align-items: center;
}
.mar-r-20 {
  margin-right: 20px;
}

.p-b-43 {
  padding-bottom: 43px;
}
.p-b-32 {
  padding-bottom: 32px;
}
.p-t-3 {
  padding-top: 3px;
}
.max-50 {
  max-width: 50px;
}
.input-md {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  height: 35px;
  font-size: 14px;
  color: #555555;
  font-weight: 300;
  max-width: 50px;
  padding: 0 10px;
}

.count-box {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  height: 35px;
  font-size: 13px;
  color: #2f2f2f;
  font-weight: 500;
  max-width: 65px;
  text-align: center;
  padding: 0 10px;
  line-height: 35px;
}

.pad-top-30 {
  padding-top: 30px;
}

.mar-bottom-20 {
  margin-bottom: 20px;
}
.error-wrapper {
  color: red;
  background: transparent;
  margin-bottom: 5px;
}

.success {
  color: green;
  background: transparent;
  margin-bottom: 5px;
  text-align: right;
  font-size: 1rem;
  /* width: 90%; */
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
}

.loader-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 55px;
  width: 55px;
}

.page-not-found-wrapper {
  width: 100%;
  height: 100vh;
  text-align: center;
  top: 50%;
  position: absolute;
}

.file-input-wrapper {
  height: 45px;
  /* margin: 2px; */
  overflow: hidden;
  position: relative;
  width: 175px;
  /* background-color: #fff; */
  cursor: pointer;
}

.file-input-wrapper > input[type="file"] {
  font-size: 40px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.file-input-wrapper > .btn-file-input {
  background-color: #494949;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  height: 34px;
  margin: 0 0 0 -1px;
  padding-left: 0;
  width: 121px;
  cursor: pointer;
}

.file-input-wrapper:hover > .btn-file-input {
  background-color: #494949;
}

#img_text {
  float: right;
  margin-right: -80px;
  margin-top: -14px;
}
.contact-num-error {
  position: absolute;
  top: 48px;
  right: 0;
  font-size: 12px;
}
.label-text:hover {
  cursor: pointer;
}
.text-link {
  cursor: pointer;
  color: #17a2b8;
  max-width: 100px;
  overflow-wrap: break-word;
}

.file-format-error {
  position: absolute;
  /* left: 70px; */
  font-size: 12px;
}
.file-input-wrapper {
  width: 160px;
}
.success-file-upload {
  color: green;
  background: transparent;
  margin-bottom: 5px;
  /* text-align: center; */
  font-size: 1rem;
  margin-left: 80px;
}

.margin-bottom {
  margin-bottom: 6px;
}
.engagys-logo {
  margin-left: 30px;
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1260px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .table th {
    padding: 18px 10px;
  }
  .call-details-left {
    width: 100%;
  }
}
